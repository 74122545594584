<template>
	<div class="body">
		<el-container style="height: 100vh;">
			<!-- 顶部导航栏 -->
			<el-header style="background-color: #001529;">
				<el-row>
					<el-col :span="4">
						<div class="u-f-item" style="height: 100%;cursor: pointer;" @click="topage('/index', -1)">
							<img :src="img_url + '/assets/static/logo.png'" alt="" width="185px" />
							<!-- <div class="u_font_16 p-l-20">华罗庚智慧校园</div> -->
						</div>
					</el-col>
					<el-col :span="16">
						<div class="u-f-item" style="padding-left: 60px;height: 100%;">
							<div class="meunitem u-f-justify font_15" @mouseover="tabTap(-1)" @click="topage('/index', -1)"
								:class="meunIndex == -1 ? 'active' : ''">首页</div>
							<div class="meunitem u-f-justify font_15" @mouseover="tabTap(index)"
								:class="index == meunIndex ? 'active' : ''" v-for="(item, index) in meunList" :key="index">
								{{ item.title }}
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="u-f-right">
							<div @click="rightdrawer = true"><el-badge :value="msgNum"><i class="el-icon-bell"
										style="margin-right: 1.875rem;font-size: 1.25rem;font-weight: bold;"></i></el-badge></div>
							<el-avatar size="small" :src="circleUrl" style="margin-right: 0.3125rem;"></el-avatar>
							<el-dropdown @command="handleCommand">
								<span style="color: #FFFFFF;">
									{{ nickname }}
									<i class="el-icon-caret-bottom" style="margin-right:0.78125vw;"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="a">个人中心</el-dropdown-item>
									<el-dropdown-item command="c" v-if="!problem1">密保问题</el-dropdown-item>
									<el-dropdown-item command="e" v-if="problem1">重设密保</el-dropdown-item>
									<el-dropdown-item command="d">修改密码</el-dropdown-item>
									<el-dropdown-item command="b">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</el-col>
				</el-row>
			</el-header>
			<!-- 主体 -->
			<el-main>
				<slot></slot>
			</el-main>
			<div class="drawer-model" v-if="drawer" @mouseover.stop="close"></div>
			<el-collapse-transition>
				<div class="drawer-box" v-if="drawer">
					<div class="drawer-meun u-f" v-for="(items, index) in meunList[meunIndex].children" :key="index">
						<div class="font_blod font_15 drawer-meun-title" style="padding-top: 10px;">{{ items.title }}</div>
						<div class="drawer-meun-list">
							<div class="drawer-meun-item u-f-justify u-f-column" v-for="(item, i) in items.children" :key="i"
								@click="topage(item.url, meunIndex)">
								<img :src="img_url + item.icon" alt="" style="border-radius: 15px;" />
								<div class="font_14">{{ item.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</el-collapse-transition>
			<el-drawer :visible.sync="rightdrawer" :with-header="false" :before-close="handleClose">
				<div class="u-f">
					<div class="right-left">
						<div class="right-left-item u-f-justify" @click="rightTap(index)" :class="rightIndex == index ? 'active' : ''"
							v-for="(item, index) in rightList" :key="index">{{ item.name }}</div>
					</div>
					<div class="right-right">
						<div class="u-f-item u-f-jsb top">
							<div class="u-f-item">
								<el-button size="mini" :type="btnIndex == 0 ? 'primary' : ''" @click="btntap(0)">未读</el-button>
								<el-button :type="btnIndex == 1 ? 'primary' : ''" size="mini" @click="btntap(1)">已读</el-button>
							</div>
							<el-button type="text" @click="yidu('all')" v-if="btnIndex == 0">全部标记为已读</el-button>
						</div>
						<div class="notice-list">
							<div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="todetails1(item)"
								style="cursor: pointer;">
								<div class="u-f-item u-f-jsb">
									<div class="u-f-item">
										<el-tag size="mini" v-if="item.author">{{ item.author }}</el-tag>
										<div style="margin-left: 8px;">{{ item.title }}</div>
									</div>
									<div class="font_12" style="color: #BBBBBB;">{{ item.createtime }}</div>
								</div>
								<div class="notice-info font_12">{{ item.info }}</div>
							</div>
						</div>
					</div>
				</div>
			</el-drawer>

			<!-- 修改密码 -->
			<el-dialog title="修改密码" :visible.sync="upPad" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							手机号码
						</div>
						<el-input v-model="mobile" placeholder="请输入手机号码" disabled style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							旧密码
						</div>
						<el-input placeholder="请输入密码" v-model="oldpassword" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							新密码
						</div>
						<el-input placeholder="请输入新密码" v-model="newpassword" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="upPad = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="updatePad">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 设置密保 -->
			<el-dialog title="设置密保" :visible.sync="mibao" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							问题1
						</div>
						<el-input v-model="problem1" placeholder="请输入问题1" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer1" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							问题2
						</div>
						<el-input v-model="problem2" placeholder="请输入问题2" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer2" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="mibao = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitMibao">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 设置密保 -->
			<el-dialog title="设置密保" :visible.sync="mibao1" width="500px" :before-close="handleClose" :show-close="false">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							问题1
						</div>
						<el-input v-model="problem1_old" placeholder="请输入问题1" style="width: 70%;" disabled></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer1_old" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							问题2
						</div>
						<el-input v-model="problem2_old" placeholder="请输入问题2" style="width: 70%;" disabled></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer2_old" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							新问题1
						</div>
						<el-input v-model="problem1_new" placeholder="请输入新密保问题1" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer1_new" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							新问题2
						</div>
						<el-input v-model="problem2_new" placeholder="请输入新密保问题2" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							答案
						</div>
						<el-input v-model="answer2_new" placeholder="请输入答案" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="mibao1 = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitMibao1">确 定</el-button>
				</span>
			</el-dialog>
		</el-container>
	</div>
</template>

<script>
import base from '../../api/base.js';
import { Message } from 'element-ui';
export default {
	name: 'VIndex',
	data() {
		return {
			nickname: '',
			msgNum: '',
			drawer: false,
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			// meunList: [],
			meunIndex: -1,
			img_url: base.imgUrl,
			rightdrawer: false,
			// 1=活动审批,2=档案审核,3=请假调休,4=课程调课,5=报修通知,6=公开课,7=安全隐患上报,8=物品领用,9=物品归还,,10=学生请假,20=学校公告,21=公文流转,30=上课通知,40=课堂预警
			rightList: [
				{ id: 0, name: '全部' },
				{ id: 1, name: '活动审批' },
				{ id: 2, name: '档案审核' },
				{ id: 3, name: '请假调休' },
				{ id: 53, name: '公章申请' },
				{ id: 4, name: '课程调课' },
				{ id: 5, name: '报修通知' },
				{ id: 6, name: '公开课' },
				{ id: 7, name: '安全隐患' },
				{ id: 8, name: '物品领用' },
				{ id: 9, name: '物品归还' },
				{ id: 10, name: '学生请假' },
				{ id: 20, name: '学校公告' },
				{ id: 21, name: '公文流转' },
				{ id: 30, name: '上课通知' },
				{ id: 40, name: '课堂预警' }
			],
			rightIndex: 0,
			btnIndex: 0,
			noticeList: [],
			upPad: false,
			mobile: '',
			mibao: false,
			problem1: '',
			answer1: '',
			problem2: '',
			answer2: '',
			mibao1: false,
			problem1_old: '',
			problem2_old: '',
			answer1_old: '',
			answer2_old: '',
			problem1_new: '',
			problem2_new: '',
			answer1_new: '',
			answer2_new: '',
			newpassword: '',
			oldpassword: ''
		};
	},
	computed: {
		meunList() {
			return this.$store.state.meunList;
		},
		passwordUpdate() {
			return this.$store.state.passwdUpdate
		}
	},
	mounted() {
		if (!this.passwordUpdate) {
			this.upPad = true;
			setTimeout(() => {
				this.$message.warning('请修改密码')
			});
		}
		if (window.localStorage.getItem('meunIndex')) {
			this.meunIndex = window.localStorage.getItem('meunIndex');
		}
		this.nickname = window.localStorage.getItem('nickname');
		this.mobile = window.localStorage.getItem('mobile');
		if (window.localStorage.getItem('avatar')) {
			this.circleUrl = window.localStorage.getItem('avatar');
		}
		this.userRules()
		this.getSystemNotice(0, 2);
		this.getProblem()
	},
	methods: {
		handleClose(done) {
			done()
		},
		// 获取我的权限列表
		userRules() {
			this.$api.login.userRules().then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.filter(item => item.ismenu === 1);
					this.$store.commit('meunList', data);
				}
			});
		},
		getProblem() {
			this.$api.setting.getProblem({
				mobile: this.mobile
			}).then(res => {
				if (res.data.code == 1) {
					this.problem1 = res.data.data.problem1;
					this.problem2 = res.data.data.problem2;
					this.problem1_old = res.data.data.problem1;
					this.problem2_old = res.data.data.problem2;
				}
			})
		},
		tabTap(index) {
			this.meunIndex = index;
			if (index != -1) {
				this.drawer = true;
			} else {
				this.drawer = false;
			}
		},
		btntap(index) {
			this.btnIndex = index;
			if (index == 0) {
				this.getSystemNotice(2)
			} else {
				this.getSystemNotice(1)
			}
		},
		rightTap(index) {
			this.rightIndex = index;
			this.btnIndex = 0;
			this.getSystemNotice(2)
		},
		close() {
			this.drawer = false;
			if (window.localStorage.getItem('meunIndex')) {
				if (this.meunIndex != window.localStorage.getItem('meunIndex')) {
					this.meunIndex = window.localStorage.getItem('meunIndex');
				}
			} else {
				this.meunIndex = 0;
			}
		},
		topage(url, index) {
			this.drawer = false;
			if (url) {
				window.localStorage.setItem('meunIndex', index);
				this.meunIndex = index;
				if (url == 'my') {
					this.$router.push({
						path: '/archives_details',
						query: {
							id: window.localStorage.getItem('id')
						}
					});
				} else if (url == 10) {
					window.open('http://school.blzzxx.cn/group.html');
				} else {
					this.$router.push(url);
				}
			} else {
				this.$message.error('正在努力开发中，敬请期待！');
			}
		},
		toIndex() {
			this.drawer = false;
			this.$router.push('/index');
		},
		handleCommand(e) {
			if (e == 'b') {
				window.localStorage.clear();
				this.$router.push('/');
			} else if (e == 'a') {
				this.$router.push({
					path: '/archives_details',
					query: {
						id: window.localStorage.getItem('id')
					}
				});
			} else if (e == 'c') {
				this.mibao = true;
			} else if (e == 'd') {
				this.upPad = true;
			} else if (e == 'e') {
				this.mibao1 = true;
			}
		},
		// 消息通知
		getSystemNotice(type) {
			let data = {}
			let data2 = {}
			if (this.rightList[this.rightIndex].id) {
				data.event = this.rightList[this.rightIndex].id
			}
			if (type == 1) {
				data.read_time = 0;
				data2.op = { "read_time": ">" };
			} else {
				data.read_time = 'null';
				data2.op = { "read_time": "is" };
			}
			data2.filter = JSON.stringify(data);
			this.$api.setting.getSystemNotice(data2).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows
					for (let i in data) {
						data[i].createtime = this.$common.timeToDate(data[i].createtime)
					}
					this.noticeList = data;
					if (this.btnIndex == 0 && res.data.data.total) {
						this.msgNum = res.data.data.total
					}
				}
			})
		},
		yidu(id) {
			this.$api.setting.readMsg({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.getSystemNotice(0)
				}
			})
		},
		todetails1(item) {
			console.log(item.event)
			const urls = new Map([
				[100, '/practice'],
				[111, '/train/borrow'],
				[112, '/train/get'],
				[113, '/train/borrow'],
				[114, '/train/check'],
				[115, '/train/audit/base'],
				[116, '/train/audit/room']
			])
			if (urls.has(item.event)) {
				this.$router.push(urls.get(item.event))
				return
			}
			if (item.event == 1) {
				this.$router.push('/activitylist')
			} else if (item.event == 2) {
				// this.$router.push('/activitylist')
			} else if (item.event == 3) {
				this.$router.push('/teacherleave')
			} else if (item.event == 4) {
				this.$router.push('/switching')
			} else if (item.event == 5) {
				this.$router.push('/repair')
			} else if (item.event == 6) {
				this.$router.push('/CourseCourse')
			} else if (item.event == 7) {
				this.$router.push('/danger')
			} else if (item.event == 8) {
				this.$router.push('/goods')
			} else if (item.event == 9 || item.event == 53) {
				// 公章申请
				this.$router.push('/chapter')
				// this.$router.push('/activitylist')
			} else if (item.event == 10) {
				// this.$router.push('/activitylist')
			} else if (item.event == 20) {
				this.$router.push('/notice')
			} else if (item.event == 21) {
				this.$router.push('/work')
			} else if (item.event == 53) {
				this.$router.push('/work')
			}
		},
		submitMibao() {
			this.$api.setting.protection({
				type: 1,
				problem1: this.problem1,
				answer1: this.answer1,
				problem2: this.problem2,
				answer2: this.answer2
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('提交成功')
					this.mibao = false;
					this.getProblem()
				} else {
					this.$message.error(res.data.msg)
				}
			})
		},
		submitMibao1() {
			this.$api.setting.protection({
				type: 2,
				problem1_old: this.problem1_old,
				answer1_old: this.answer1_old,
				problem2_old: this.problem2_old,
				answer2_old: this.answer2_old,
				problem1_new: this.problem1_new,
				answer1_new: this.answer1_new,
				problem2_new: this.problem2_new,
				answer2_new: this.answer2_new
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('提交成功')
					this.mibao1 = false;
					this.getProblem()
				} else {
					this.$message.error(res.data.msg)
				}
			})
		},
		updatePad() {
			const newpasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
			if (!newpasswordRegex.test(this.newpassword)) {
				this.$message.warning('长度限制为8-16位置，必须包含至少一个大写字母一个小写字母一个数字');
				return;
			}
			this.$api.setting.updatePad({
				oldpassword: this.oldpassword,
				newpassword: this.newpassword
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('修改成功')
					this.upPad = false;
					window.localStorage.clear();
					this.$router.push('/');
				} else {
					this.$message.error(res.data.msg)
				}
			})
		}
	}
};
</script>

<style lang="scss">
.body {
	.el-container {
		position: relative;
		background-color: #f0f2f5;

		.el-header {
			color: #fff;
			font-size: 0.625vw;
			line-height: 60px;
			z-index: 1604;

			.el-badge__content {
				top: 1.04vw;
				right: 1.98vw;
			}

			.meunitem {
				padding: 0 2.083vw;
				height: 100%;
				cursor: pointer;
			}

			.meunitem:hover {
				background-color: #334454;
			}

			.active {
				background-color: #334454;
			}
		}

		.drawer-model {
			background-color: rgba(0, 0, 0, 0.5);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 1602;
		}

		.drawer-box {
			position: fixed;
			width: 100%;
			height: auto;
			padding-bottom: 20px;
			background-color: #ffffff;
			top: 60px;
			z-index: 1603;

			.drawer-meun {
				padding-top: 10px;

				.drawer-meun-title {
					width: 20px;
					margin-left: 26%;
					line-height: 15px;
				}

				.drawer-meun-list {
					display: flex;
					flex-wrap: wrap;

					.drawer-meun-item {
						cursor: pointer;
						width: 140px;
						padding: 10px 0;

						img {
							margin-bottom: 5px;
							width: 30%;
						}

						div {
							color: #696969;
						}
					}

					.drawer-meun-item:hover {
						background-color: #eeeeee !important;
					}
				}
			}
		}

		.drawer-box::-webkit-scrollbar {
			display: none;
		}

		.right-left {
			width: 20vw;
			background-color: #F5F7FA;
			height: 100vh;

			.right-left-item {
				padding: 10px 0;
				color: #757575;
				cursor: pointer;
			}

			.active {
				background-color: #1890FF;
				color: #FFFFFF;
			}
		}

		.right-right {
			width: 80vw;
			height: 100vh;

			.top {
				padding: 0 15px;
				border-bottom: 1px solid #EEEEEE;
				height: 5vh;
				line-height: 5vh;
			}

			.notice-list {
				height: 95vh;
				overflow-y: auto;

				.notice-item {
					padding: 20px;
					border-bottom: 1px solid #EEEEEE;

					.notice-info {
						margin-top: 10px;
						color: #A4A4A4;
					}
				}
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;

		.el-dialog__title {
			font-size: 16px;
		}
	}

	.el-dialog__body {
		padding: 0 20px 35px 20px;

		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;

				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;

					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}

				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}

			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}

			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}

			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}

			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}

	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}

.moveL-enter-active,
.moveL-leave-active {
	transition: all 0.3s linear;
	transform: translateX(0%);
}

.moveL-enter,
.moveL-leave {
	transform: translateX(-100%);
}

.moveL-leave-to {
	transform: translateX(-100%);
}

.u_font_16 {
	font-size: 16px;
}

.u_font_15 {
	font-size: 15px;
}
</style>
